.row {
  width: 100%;
  padding: 0px 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.item {
  margin-left: 8px;
}
