.header {
  height: 84px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  resize: both;
}
